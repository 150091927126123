export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  DontShowErrorWhenOnlyTaxIsShown: 'specs.stores.DontShowErrorWhenOnlyTaxIsShown',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  HideTopCheckoutButtonsForStudioAndEditorX: 'specs.stores.HideTopCheckoutButtonsForStudioAndEditorX',
  ShouldFixCurrencyEscapingInMinimumOrderAmountMessage:
    'specs.stores.ShouldFixCurrencyEscapingInMinimumOrderAmountMessage',
  ShouldFixPriceOverlapsInCartPage: 'specs.stores.ShouldFixPriceOverlapsInCartPage',
  ChangeMaxViewportMobile: 'specs.stores.ChangeMaxViewportMobile',
  UseUseEffectOnShowTopButton: 'specs.stores.UseUseEffectOnShowTopButton',
  UseGlobalCssForOneColumnCart: 'specs.stores.UseGlobalCssForOneColumnCart',
} as const;
